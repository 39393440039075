const menuList = [
    {
        title: '实时地图',
        key: '/admin/home',
        icon: 'dashboard',
    },
    {
        title: '设备管理',
        key: '/admin/device',
        icon: 'car',
        sub: [
            
            {
                title: '设备列表',
                key: '/admin/device/list',
                icon: '',
            },
            {
                title: '车辆列表',
                key: '/admin/device/carlist',
                icon: '',
            },
            {
                title: '车辆类型',
                key: '/admin/device/cartype',
                icon: '',
            },
            
            // {
            //     title: '事项批量录入',
            //     key: '/admin/dbmanage/uploads',
            //     icon: '',
            // }
        ]
    },
   
    {
        title: '人员管理',
        key: '/admin/hmmanage',
        icon: 'user',
        sub: [
            
            {
                title: '人员列表',
                key: '/admin/hmmanage/list',
                icon: '',
            },
            // {
            //     title: '人员批量录入',
            //     key: '/admin/hmmanage/uploads',
            //     icon: '',
            // },
           
            
        ]
    },
    {
        title: '地图管理',
        key: '/admin/map',
        icon: 'file-markdown',
        sub: [
            
            
            {
                title: '设备地图',
                key: '/admin/map/search',
                icon: '',
            },
            // {
            //     title: '部门批量录入',
            //     key: '/admin/bmmanage/uploads',
            //     icon: '',
            // },
            
        ]
    },
    {
        title: '预警管理',
        key: '/admin/alert',
        icon: 'fire',
        sub: [
            
            {
                title: '预警列表',
                key: '/admin/alert/list',
                icon: '',
            },
            {
                title: '预警区域列表',
                key: '/admin/alert/maplist',
                icon: '',
            },
            {
                title: '预警区域设置',
                key: '/admin/alert/mapsetting',
                icon: '',
            },
            // {
            //     title: '人员批量录入',
            //     key: '/admin/hmmanage/uploads',
            //     icon: '',
            // },
           
            
        ]
    },

   



    {
        title: '系统设置',
        key: '/admin/setting',
        icon: 'setting',
        sub: [
            {
                title: '管理员账号',
                key: '/admin/setting/account',
                icon: '',
            },


        ]
    },
];
export default menuList;
