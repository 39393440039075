import React from 'react';
import { Select } from 'antd'
import moment from 'moment';
import { parse, stringify } from 'qs';
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'

const Option = Select.Option;
export default {
  getOptionList(data){
    if(!data){
        return [];
    }
    let options = [] //[<Option value="0" key="all_key">全部</Option>];
    data.map((item)=>{
        options.push(<Option value={item.id} key={item.id}>{item.name}</Option>)
    })
    return options;
},
formateDate(time){
  if(!time)return '';
  let date = new Date(time);
  return date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+' '+date.getHours()+':'+date.getMinutes()+':'+date.getSeconds();
},
formateDateWX(date) {
  let fmt =  'yyyy-MM-dd hh:mm:ss'
  var datetem=new Date(date)
  if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, datetem.getFullYear())
  }
  const o = {
      // 'y+': date.getFullYear(),
      'M+': datetem.getMonth() + 1,
      'd+': datetem.getDate(),
      'h+': datetem.getHours(),
      'm+': datetem.getMinutes(),
      's+': datetem.getSeconds()
  }
  for (let k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
          const val = o[k] + '';
          fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? val : ('00' + val).substr(val.length));
      }
  }
  return fmt;
},

// formateDate(date, rule) {
//   let fmt = rule || 'yyyy-MM-dd hh:mm:ss'
//   var datetem=new Date(date)
//   if (/(y+)/.test(fmt)) {
//       fmt = fmt.replace(RegExp.$1, datetem.getFullYear())
//   }
//   const o = {
//       // 'y+': date.getFullYear(),
//       'M+': datetem.getMonth() + 1,
//       'd+': datetem.getDate(),
//       'h+': datetem.getHours(),
//       'm+': datetem.getMinutes(),
//       's+': datetem.getSeconds()
//   }
//   for (let k in o) {
//       if (new RegExp(`(${k})`).test(fmt)) {
//           const val = o[k] + '';
//           fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? val : ('00' + val).substr(val.length));
//       }
//   }
//   return fmt;
// },
// 格式化金额,单位:分(eg:430分=4.30元)
formatFee(fee, suffix = '') {
  if (!fee) {
      return 0;
  }
  return Number(fee).toFixed(2) + suffix;
},
// 格式化公里（eg:3000 = 3公里）
formatMileage(mileage, text) {
  if (!mileage) {
      return 0;
  }
  if (mileage >= 1000) {
      text = text || " km";
      return Math.floor(mileage / 100) / 10 + text;
  } else {
      text = text || " m";
      return mileage + text;
  }
},
// 隐藏手机号中间4位
formatPhone(phone) {
  phone += '';
  return phone.replace(/(\d{3})\d*(\d{4})/g, '$1***$2')
},
// 隐藏身份证号中11位
formatIdentity(number) {
  number += '';
  return number.replace(/(\d{3})\d*(\d{4})/g, '$1***********$2')
},

/* @param {*选中行的索引} selectedRowKeys
* @param {*选中行对象} selectedItem
*/
updateSelectedItem(selectedRowKeys, selectedRows, selectedIds) {
   if (selectedIds) {
       this.setState({
           selectedRowKeys,
           selectedIds: selectedIds,
           selectedItem: selectedRows
       })
   } else {
       this.setState({
           selectedRowKeys,
           selectedItem: selectedRows
       })
   }
},
}

export function getTimeDistance(type) {
    const now = new Date();
    const oneDay = 1000 * 60 * 60 * 24;
  
    if (type === 'today') {
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      return [moment(now), moment(now.getTime() + (oneDay - 1000))];
    }
  
    if (type === 'week') {
      let day = now.getDay();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
  
      if (day === 0) {
        day = 6;
      } else {
        day -= 1;
      }
  
      const beginTime = now.getTime() - day * oneDay;
  
      return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
    }
  
    if (type === 'month') {
      const year = now.getFullYear();
      const month = now.getMonth();
      const nextDate = moment(now).add(1, 'months');
      const nextYear = nextDate.year();
      const nextMonth = nextDate.month();
  
      return [
        moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
        moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
      ];
    }
  
    if (type === 'year') {
      const year = now.getFullYear();
  
      return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
    }
  }

       /*
* th => 表头
* data => 数据
* fileName => 文件名
* fileType => 文件类型
* sheetName => sheet页名
*/
//导出封装好的方法
export function toExcel({ th, data, fileName, fileType, sheetName }) {
  data.unshift(th)
  const wb = new Workbook()
  const ws = data2ws(data)
  sheetName = sheetName || 'sheet1'
  wb.SheetNames.push(sheetName)
  wb.Sheets[sheetName] = ws
  fileType = fileType || 'xlsx'
  var wbout = XLSX.write(wb, { bookType: fileType, bookSST: false, type: 'binary' })
  fileName = fileName || '列表'
  saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${fileName}.${fileType}`)
}

function Workbook() {
  if (!(this instanceof Workbook)) {
    return new Workbook()
  }
  this.SheetNames = []
  this.Sheets = {}
}

function data2ws(data) {
  const ws = {}
  const range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } }
  for (let R = 0; R !== data.length; ++R) {
    for (let C = 0; C !== data[R].length; ++C) {
      if (range.s.r > R) range.s.r = R
      if (range.s.c > C) range.s.c = C
      if (range.e.r < R) range.e.r = R
      if (range.e.c < C) range.e.c = C
      const cell = { v: data[R][C] }
      if (cell.v == null) continue
      const cellRef = XLSX.utils.encode_cell({ c: C, r: R })
      if (typeof cell.v === 'number') cell.t = 'n'
      else if (typeof cell.v === 'boolean') cell.t = 'b'
      else if (cell.v instanceof Date) {
        cell.t = 'n'
        cell.z = XLSX.SSF._table[14]
      } else {
        cell.t = 's'
      }
      ws[cellRef] = cell
    }
  }
  if (range.s.c < 10000000) {
    ws['!ref'] = XLSX.utils.encode_range(range)
  }
  return ws
}

function s2ab(s) {
  let buf = new ArrayBuffer(s.length)
  let view = new Uint8Array(buf)
  for (let i = 0; i !== s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xFF
  }
  return buf
}
  