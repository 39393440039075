import React from 'react'
import { Card, Form,Input,Col,Button,Modal,Select,DatePicker } from 'antd'
import {Map, Marker, NavigationControl, InfoWindow,ZoomControl,CityListControl,MapTypeControl,point} from 'react-bmapgl';
// import axios from '../../axios'
import axios from 'axios'
import Utils from '../../util/utils'
import { connect } from 'react-redux';
import {loadData} from '../../redux/user.redux'
import BaseForm from '../../components/BaseForm'
// import { BaiduMap,asyncWrapper ,NavigationControl,ScaleControl,MapTypeControl,OverviewMapControl} from 'react-baidu-maps';
// const AsyncMap = asyncWrapper(BaiduMap);
// var BMap = window.BMap//取出window中的BMap对象
// var map = new BMap.Map("allmap"); // 创建Map实例
const { Search } = Input;
const FormItem = Form.Item;
const {  RangePicker } = DatePicker;
const offlinecarlogo = "https://qiniu.mnoiot.com/alert/grey-car.png"

@connect(
    state=>state.user,
    {loadData}
    // {logoutSubmit}
  )
export default class Dashboard extends React.Component{

    state = {
        // mapState:false
        mapsearch:'',
        centerpoit:null,
        loading:false,
        data:null,
        querypointmap:<Map ref={ref => {this.map = ref?.map}} style={{ width: '100%', height: '700px' }} center={"许昌"} zoom="12">
           
        <NavigationControl /> 
        <ZoomControl/>
        <MapTypeControl/>
        <CityListControl 
        // anchor="BMAP_ANCHOR_TOP_RIGHT"
        offset={{ width: 0, height: 30 }}/>
        {/* <InfoWindow position={{lng: 116.402544, lat: 39.928216}} text="内容" title="标题"/> */}
    </Map>,
        center:'许昌'
        // {lng: 116.402544, lat: 39.928216},
    }
    //  BMap = window.BMap//取出window中的BMap对象
    // map = {}

    // 表单封装，通过构建表单对象，在BaseForm中进行统一渲染
    
    handleSearch=()=>{

    }

    handleFilterSubmit = e=>{
        e.preventDefault();
        this.filterForm.props.form.validateFields((err, values) => {
          if (!err) {
            console.log('Received filter of form: ', values);

            this.setState({
                isFilterVisible:false,
                ...values
            })
            // this.props.updateMinasim({...values})
            // this.fetch({
            //     size: this.state.size,
            //     page:this.state.page,
            //     phonenumber:this.props.phonenumber,
            //     search:this.state.mapsearch,
            //     minasimsortField: this.props.minasimsortField,
            //     minasimsortOrder: this.props.minasimsortOrder,
            //     ...values
            // });

            this.fetchtrack({
                ...values
            })

            // axios.get('/carmanage/cartrack',{
            //     params:{
            //         // size: 1,
            //         // page:1,
            //         // phonenumber:this.props.phonenumber,
            //         // search:'',
            //         // bangown:this.props.bangown,
            //         // state:this.props.state,
            //         ...values
            //     }
            // },{
            //     type: 'json'
            // })
            //     .then(res=>{
                    
            //         this.setState({
            //             minauserlist:res.data.results
            //         })
            //         console.log('minauserlist--->>',this.state.minauserlist)
            //     }).catch((err)=>{
            //         console.log(err)
            //         return
            //     })
          }
        });
    }

    handleOperator = (type)=>{
        let item = this.state.selectedItem;
        if(type =='create'){
            this.setState({
                title:'创建资源',
                isVisible:true,
                type
            })
        }else if(type=="filter"){
                
            this.setState({
                filterTitle:'筛选轨迹',
                isFilterVisible:true
            })
        
        }
        else if(type=="field"){
                
        this.setState({
            isFieldVisible:true
        })
    
        }
        else if(type=="edit" || type=='detail'){
            if(!item){
                Modal.info({
                    title: '信息',
                    content: '请选择一个用户'
                })
                return;
            }
            this.setState({
                title:type=='edit'?'编辑用户':'查看详情',
                isVisible:true,
                userInfo:item,
                type
            })
        }else if(type=="delete"){
            if(!item){
                Modal.info({
                    title: '信息',
                    content: '请选择一个用户'
                })
                return;
            }
            Utils.ui.confirm({
                text:'确定要删除此用户吗？',
                onOk:()=>{
                    axios.ajax({
                        url:'/user/delete',
                        data:{
                            params:{
                                id:item.id
                            }
                        }
                    }).then((res)=>{
                        if(res.code ==0){
                            this.setState({
                                isVisible:false
                            })
                            this.requestList();
                        }
                    })
                }
            })
        }
    }

//处理表单请求接口  查询车辆位置
async  fetch(params = {}){
    console.log('params:', params);
    // console.log('state---?>>>>>:', this.state);
    this.setState({ loading: true });
    this.map.clearOverlays()
    await axios.get('/map/mapiccidlist',{
        params:{
            ...params,
        }   
    },{
        type: 'json'
    })
        .then(res=>{
            
            // const pagination = { ...this.state.pagination };
            // Read total count from server
            console.log('form---->>>>',res.data)
            // console.log('form---->>>>',res.data.results)
            // pagination.total = data.totalCount;
            // pagination.total = 200;
            // if(res.data.length>0)
            this.setState({
              loading: false,
              data: res.data.results,
            //   center:{lat:res.data?.results[0].baidulocation.lat,lng:res.data?.results[0].baidulocation.lon}
            //   total:res.data.count,
            //   pagination,
            });

            let xjPointIcon = new BMapGL.Icon("https://qiniu.mnoiot.com/alert/yellow-car.png", new BMapGL.Size(36, 42), {
              imageSize: new BMapGL.Size(36, 42),
              anchor: new BMapGL.Size(18, 42)
          });
          let gcPointIcon = new BMapGL.Icon("https://qiniu.mnoiot.com/alert/red-car.png", new BMapGL.Size(36, 42), {
              imageSize: new BMapGL.Size(36, 42),
              anchor: new BMapGL.Size(18, 42)
          });
            if(res.data?.results.length>0){
                // this.searchmap
                console.log('setcenter!!!resultsresults>>>>',res.data.results)
                res.data.results.map((val)=>{
                    if(val?.baidulocation?.lat){
                        console.log('setcenter!!!>>>>',val)
                        // this.setState({
                        //     center:new BMapGL.Point(val.baidulocation.lon,val.baidulocation.lat)
                        // })devstate
                        // if(val.bangown=='1'&&val.devstate==0){
                        //     console.log("val.cartypeid.carlogourl--->>",val.cartypeid.carlogourl)
                        //     let carPointIcon = new BMapGL.Icon(offlinecarlogo, new BMapGL.Size(36, 42), {
                        //       imageSize: new BMapGL.Size(36, 42),
                        //       anchor: new BMapGL.Size(18, 42)
                        //   });
                        if(val.bangown=='1'){
                          console.log("val.cartypeid.carlogourl--->>",val.cartypeid.carlogourl)
                          let carPointIcon = null
                          if(val.devstate==0){
                            carPointIcon = new BMapGL.Icon(offlinecarlogo, new BMapGL.Size(36, 42), {
                                imageSize: new BMapGL.Size(36, 42),
                                anchor: new BMapGL.Size(18, 42)
                            });
                          }else{
                            carPointIcon = new BMapGL.Icon(val.cartypeid.carlogourl, new BMapGL.Size(36, 42), {
                                imageSize: new BMapGL.Size(36, 42),
                                anchor: new BMapGL.Size(18, 42)
                            });
                          }
                        //   let carPointIcon = new BMapGL.Icon(val.cartypeid.carlogourl, new BMapGL.Size(36, 42), {
                        //     imageSize: new BMapGL.Size(36, 42),
                        //     anchor: new BMapGL.Size(18, 42)
                        // });
                      //   let carPointIcon = new BMapGL.Icon("https://qiniu.mnoiot.com/alert/start_point.png", new BMapGL.Size(36, 42), {
                      //     imageSize: new BMapGL.Size(36, 42),
                      //     anchor: new BMapGL.Size(18, 42)
                      // });

                        var carMarker = new BMapGL.Marker(new BMapGL.Point(val.baidulocation.lon,val.baidulocation.lat),{ icon: carPointIcon }); 
                        this.map.addOverlay(carMarker)

                              var opts = {
                                width: 250,
                                height: 170,
                                title: '设备信息'
                            };
                            var infoWindow = new BMapGL.InfoWindow(`<div>车牌：${val.carid?.carnum}</div> 
                            <div>联系人：${val.minauserid?.name}</div>
                            <div>手机号：${val.minauserid?.phoneNumber}</div>
                            <div>iccid: ${val.iccidNum}</div> 
                            <div>上报时间:${Utils.formateDateWX(val.location.updatedAt)} </div>                          
                            <div>经度：${val.baidulocation.lon} </div>
                            <div>纬度：${val.baidulocation.lat}</div>`, opts);
                            // this.map.openInfoWindow(infoWindow, point); // 开启信息窗口
                            // 点标记添加点击事件
                            if(val?.baidulocation.lat){
                              carMarker.addEventListener('click', function () {
                                this.map.openInfoWindow(infoWindow, new BMapGL.Point(val.baidulocation.lon,val.baidulocation.lat)); // 开启信息窗口
                            });
                            }
                            // carMarker.addEventListener('click', function () {
                            //     this.map.openInfoWindow(infoWindow, new BMapGL.Point(val.baidulon,val.baidulat)); // 开启信息窗口
                            // });
                        }
                        else{
                          var marker = new BMapGL.Marker(new BMapGL.Point(val.baidulocation.lon,val.baidulocation.lat)); 
                          this.map.addOverlay(marker)
                          var opts = {
                            width: 250,
                                height: 170,
                            title: '设备信息'
                        };
                        var infoWindow = new BMapGL.InfoWindow(`<div>车牌：${val.carid?.carnum}</div> 
                        <div>联系人：${val.minauserid?.name}</div>
                        <div>手机号：${val.minauserid?.phoneNumber}</div>
                        <div>iccid: ${val.iccidNum}</div>
                        <div>上报时间:${Utils.formateDateWX(val.location.updatedAt)} </div> 
                        <div>经度：${val.baidulocation.lon} </div>
                        <div>纬度：${val.baidulocation.lat}</div>`, opts);
                        // this.map.openInfoWindow(infoWindow, point); // 开启信息窗口
                        // 点标记添加点击事件
                        if(val?.baidulocation.lat){
                          marker.addEventListener('click', function () {
                            this.map.openInfoWindow(infoWindow, new BMapGL.Point(val.baidulocation.lon,val.baidulocation.lat)); // 开启信息窗口
                        });
                        }
                       
                        }
                        
                        
                        // console.log("this.state.center--->>>",this.state.center)
                    }
                    
                    
                })
                // this.map.centerAndZoom(this.state.center,15)
                
            }
            
        })
  };

  //处理表单请求接口
async  fetchoverlay(params = {}){
  console.log('params:', params);
  // console.log('state---?>>>>>:', this.state);
  this.setState({ loading: true });
  await axios.get('/map/overlaymap',{
      params:{
          ...params,
      }   
  },{
      type: 'json'
  })
      .then(res=>{
          
          // const pagination = { ...this.state.pagination };
          // Read total count from server
          console.log('form---->>>>',res.data.results)
          // console.log('form---->>>>',res.data.results)
          // pagination.total = data.totalCount;
          // pagination.total = 200;
          // if(res.data.length>0)
          this.setState({
            loading: false,
            data: res.data.results,
          //   total:res.data.count,
          //   pagination,
          });
          if(res?.data?.results.length>0){
              res.data.results.map(val=>{
                  if(val.overlaytype=='circle'){
                      var circlepoint = new BMapGL.Point(val.overlaypoints[0].lng,val.overlaypoints[0].lat)
                      this.setState({centerpoit:circlepoint})
                      var circle = new BMapGL.Circle(circlepoint, val.radius, {
                          strokeColor: 'blue',
                          strokeWeight: 2,
                          strokeOpacity: 0.5
                      });
                      this.map.addOverlay(circle)
                      var opts = {
                        width: 200,
                            height: 100,
                        title: '区域信息'
                    };
                    var infoWindow = new BMapGL.InfoWindow(`<div>区域名：${val.overlayname}</div> 
                    `, opts);
                    // this.map.openInfoWindow(infoWindow, point); // 开启信息窗口
                    // 点标记添加点击事件
                    
                        circle.addEventListener('click', function () {
                        this.map.openInfoWindow(infoWindow, circlepoint); // 开启信息窗口
                    });
                    

                  }else if(val.overlaytype=='rectangle'){
                       // 创建矩形
                       let rectanglepoints=[]
                       val.overlaypoints.map(item=>{
                          rectanglepoints.push(new BMapGL.Point(item.lng,item.lat))
                          this.setState({centerpoit:new BMapGL.Point(item.lng,item.lat)})
                       })
                      var rectangle = new BMapGL.Polygon(
                          rectanglepoints, {
                          strokeColor: 'blue',
                          strokeWeight: 2,
                          strokeOpacity: 0.5
                      });
                      this.map.addOverlay(rectangle)

                      var opts = {
                        width: 200,
                            height: 100,
                        title: '区域信息'
                    };
                    var infoWindow = new BMapGL.InfoWindow(`<div>区域名：${val.overlayname}</div> 
                   `, opts);
                    // this.map.openInfoWindow(infoWindow, point); // 开启信息窗口
                    // 点标记添加点击事件
                    
                        rectangle.addEventListener('click', function () {
                        this.map.openInfoWindow(infoWindow, rectanglepoints[0]); // 开启信息窗口
                    });
                    

                  }else if(val.overlaytype=='polyline'){
                      let polylinepoints=[]
                       val.overlaypoints.map(item=>{
                          polylinepoints.push(new BMapGL.Point(item.lng,item.lat))
                          this.setState({centerpoit:new BMapGL.Point(item.lng,item.lat)})
                       })
                      var polyline = new BMapGL.Polyline(
                          polylinepoints, {
                          strokeColor: 'blue',
                          strokeWeight: 2,
                          strokeOpacity: 0.5
                      });
                      this.map.addOverlay(polyline)

                      var opts = {
                        width: 200,
                            height: 100,
                        title: '区域信息'
                    };
                    var infoWindow = new BMapGL.InfoWindow(`<div>区域名：${val.overlayname}</div> 
                    `, opts);
                    // this.map.openInfoWindow(infoWindow, point); // 开启信息窗口
                    // 点标记添加点击事件
                  
                    polyline.addEventListener('click', function () {
                        this.map.openInfoWindow(infoWindow, polylinepoints[0]); // 开启信息窗口
                    });
                    
                  }else if(val.overlaytype=='polygon'){
                        // 创建多边形
                       let polygonpoints=[]
                       val.overlaypoints.map(item=>{
                          polygonpoints.push(new BMapGL.Point(item.lng,item.lat))
                          this.setState({centerpoit:new BMapGL.Point(item.lng,item.lat)})
                       })
                      var polygon = new BMapGL.Polygon(
                          polygonpoints, {
                          strokeColor: 'blue',
                          strokeWeight: 2,
                          strokeOpacity: 0.5
                      });
                      this.map.addOverlay(polygon)
                      var opts = {
                        width: 200,
                            height: 100,
                        title: '区域信息'
                    };
                    var infoWindow = new BMapGL.InfoWindow(`<div>区域名：${val.overlayname}</div> 
                   `, opts);
                    // this.map.openInfoWindow(infoWindow, point); // 开启信息窗口
                    // 点标记添加点击事件
                    
                    polygon.addEventListener('click', function () {
                        this.map.openInfoWindow(infoWindow, polygonpoints[0]); // 开启信息窗口
                    });
                    
                  }

                  // this.map.centerAndZoom(this.state.centerpoit,15)
              })
          }
          
      })
};

  async  fetchtrack(params = {}){
    console.log('trackparams:', params);
    // console.log('state---?>>>>>:', this.state);
    this.setState({ loading: true });
    // this.map.clearOverlays()
    await axios.get('/map/maptrack',{
        params:{
            ...params,
        }   
    },{
        type: 'json'
    })
        .then(res=>{
            
            // const pagination = { ...this.state.pagination };
            // Read total count from server
            console.log('form---->>>>',res.data)
            // console.log('form---->>>>',res.data.results)
            // pagination.total = data.totalCount;
            // pagination.total = 200;
            // if(res.data.length>0)
            this.setState({
              loading: false,
              data: res.data.results,
            //   center:{lat:res.data?.results[0].baidulocation.lat,lng:res.data?.results[0].baidulocation.lon}
            //   total:res.data.count,
            //   pagination,
            });
            //添加起始图标
        let startPointIcon = new BMapGL.Icon("https://qiniu.mnoiot.com/alert/start_point.png", new BMapGL.Size(36, 42), {
            imageSize: new BMapGL.Size(36, 42),
            anchor: new BMapGL.Size(18, 42)
        });
        let endPointIcon = new BMapGL.Icon("https://qiniu.mnoiot.com/alert/end_point.png", new BMapGL.Size(36, 42), {
            imageSize: new BMapGL.Size(36, 42),
            anchor: new BMapGL.Size(18, 42)
        });
        
        // var carMarkerStart = new BMapGL.Marker(startPoint, { icon: startPointIcon });
        // this.map.addOverlay(carMarkerStart);

            if(res.data?.results.length>0){
                // this.searchmap
                console.log('setcenter!!!resultsresults>>>>',res.data.results)
                // this.setState({
                //     center:{lat:res.data?.results[0].baidulat,lng:res.data?.results[0].baidulon}
                // })
                let polylinepoints=[]
                res.data.results.map((val,key)=>{
                    if(val?.baidulon){
                        if(key==0){
                            console.log('setcenter!!!>>>>key---',val,key)
                        this.setState({
                            center:new BMapGL.Point(val.baidulon,val.baidulat)
                        })
                        var startPoint = new BMapGL.Point(val.baidulon,val.baidulat)
                        // this.map.addOverlay(marker)

                        var carMarkerStart = new BMapGL.Marker(startPoint, { icon: startPointIcon });
                        this.map.addOverlay(carMarkerStart);

                        var opts = {
                            width: 200,
                            height: 100,
                            title: '点位信息'
                        };
                        var infoWindow = new BMapGL.InfoWindow(`经度：${val.baidulon} 纬度：${val.baidulat}`, opts);
                        // this.map.openInfoWindow(infoWindow, point); // 开启信息窗口
                        // 点标记添加点击事件
                        carMarkerStart.addEventListener('click', function () {
                            this.map.openInfoWindow(infoWindow, new BMapGL.Point(val.baidulon,val.baidulat)); // 开启信息窗口
                        });
                        
                        console.log("this.state.center--->>>",this.state.center)
                        }
                        if(key==res.data?.results.length-1){
                            var endPoint = new BMapGL.Point(val.baidulon,val.baidulat)
                            // this.map.addOverlay(marker)
    
                            var carMarkerEnd = new BMapGL.Marker(endPoint, { icon: endPointIcon });
                            this.map.addOverlay(carMarkerEnd);

                            var opts = {
                                width: 200,
                                height: 100,
                                title: '点位信息'
                            };
                            var infoWindow = new BMapGL.InfoWindow(`经度：${val.baidulon} 纬度：${val.baidulat}`, opts);
                            // this.map.openInfoWindow(infoWindow, point); // 开启信息窗口
                            // 点标记添加点击事件
                            carMarkerEnd.addEventListener('click', function () {
                                this.map.openInfoWindow(infoWindow, new BMapGL.Point(val.baidulon,val.baidulat)); // 开启信息窗口
                            });
                        }

                        polylinepoints.push(new BMapGL.Point(val.baidulon,val.baidulat))

                        
                    }
                    
                    
                    
                })

                var polyline = new BMapGL.Polyline(
                    polylinepoints, {
                    strokeColor: 'blue',
                    strokeWeight: 4,
                    strokeOpacity: 0.5
                });
                this.map.addOverlay(polyline)

                
                // val.overlaypoints.map(item=>{
                //    polylinepoints.push(new BMapGL.Point(item.lng,item.lat))
                //    this.setState({centerpoit:new BMapGL.Point(item.lng,item.lat)})
                // })
            //    var polyline = new BMapGL.Polyline(
            //        polylinepoints, {
            //        strokeColor: 'blue',
            //        strokeWeight: 2,
            //        strokeOpacity: 0.5
            //    });
            //    this.map.addOverlay(polyline)
                this.map.centerAndZoom(this.state.center,15)
                
            }
            
        })
  };
    // 列表请求
    async requestList (){
      
        // this.fetch({
        //     phonenumber:this.props.phonenumber,
        //     search:this.props.mapsearch,
            
        // })
        this.fetchoverlay({
            phonenumber:this.props.phonenumber,
            // search:value,
            
        })

        this.fetch({
          phonenumber:this.props.phonenumber,
          // search:value,
          
      })
      
        // this.setState({mapState:true})
        // axios.ajax({
        //     url:'/map/bike_list',
        //     data:{
        //         params:this.params
        //     }
        // }).then((res)=>{
        //     if(res){
        //         this.setState({
        //             total_count:res.result.total_count
        //         },()=>{

        //         })
        //         this.renderMap(res.result);
        //     }
        // })
        // await this.renderMap();
        
    }

    // 查询表单
    // handleFilterSubmit = (filterParams) => {
    //     this.params = filterParams;
    //     this.requestList();
    // };
    handleMoveSearch=(e)=>{
        this.props.loadData({
            mapsearch:e.target.value
        })
        this.setState({
            mapsearch:e.target.value
        })
        // this.props.updateMinasim({
        //     minasearch:e.target.value
        // })
    }
    handleclearoverlay=()=>{
        // console.log("this.state.map",this.state.map)
        this.map.clearOverlays()
    }

    componentDidMount(){
        
        // window.setTimeout(this.requestList(),100000)
        console.log("rerender!!!!!!!!!",this.state.center)
        this.requestList()
        this.interval = setInterval(() => this.requestList(), 60000);
        // this.setState({
        //     querypointmap:
        // })
        // this.searchmap.clearo
        
    }
    componentWillUnmount(){
        // this.map = null
        this.setState({
            querypointmap:null
        })
        clearInterval(this.interval);
    }

    // 渲染地图
    renderMap = () => {
        // this.props.history.push('/admin/map')
        this.map = new BMap.Map("maper", {enableMapClick: false}); // 创建Map实例

        this.map.centerAndZoom(new BMap.Point(116.404, 39.915), 11); // 初始化地图,设置中心点坐标和地图级别
        this.map.addControl(new BMap.MapTypeControl()); //添加地图类型控件
        this.map.setCurrentCity("北京"); // 设置地图显示的城市 此项是必须设置的
        this.map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
        var top_right_control = new window.BMap.ScaleControl({anchor: window.BMAP_ANCHOR_TOP_LEFT});
        var top_right_navigation = new window.BMap.NavigationControl({anchor: window.BMAP_ANCHOR_TOP_LEFT});
        //添加控件和比例尺
        this.map.addControl(top_right_control);
        this.map.addControl(top_right_navigation);


        // console.log("<<<<-----renders---->>>");
        // let list = res.route_list;
        // this.map = new window.BMap.Map("container", {enableMapClick: false});
        // console.log("<<<<-----renders---->>>"+this.map);
        // let gps1 = list[0].split(',');
        // let startPoint = new window.BMap.Point(gps1[0], gps1[1]);
        // let gps2 = list[list.length - 1].split(',');
        // let endPoint = new window.BMap.Point(gps2[0], gps2[1]);
        // this.map.setCurrentCity("北京");
        // var point = new BMap.Point(116.404, 39.915);


        // this.map.centerAndZoom(point, 11);
        // this.map.enableScrollWheelZoom(true);
        // map.clearOverlays();

        //添加起始图标
        // let startPointIcon = new window.BMap.Icon("../../resource/assets/start_point.png", new window.BMap.Size(36, 42), {
        //     imageSize: new window.BMap.Size(36, 42),
        //     anchor: new window.BMap.Size(18, 42)
        // });

        // var bikeMarkerStart = new window.BMap.Marker(startPoint, { icon: startPointIcon });
        // this.map.addOverlay(bikeMarkerStart);

        // let endPointIcon = new window.BMap.Icon("../../resource/assets/end_point.png", new window.BMap.Size(36, 42), {
        //     imageSize: new window.BMap.Size(36, 42),
        //     anchor: new window.BMap.Size(18, 42)
        // });
        // var bikeMarkerEnd = new window.BMap.Marker(endPoint, { icon: endPointIcon });
        // this.map.addOverlay(bikeMarkerEnd);

        // let routeList = [];
        // list.forEach((item)=>{
        //     let p = item.split(",");
        //     let point = new window.BMap.Point(p[0], p[1]);
        //     routeList.push(point);
        // })
        // 行驶路线
        // var polyLine = new window.BMap.Polyline(routeList, {
        //     strokeColor: "#ef4136",
        //     strokeWeight: 3,
        //     strokeOpacity: 1
        // });
        // this.map.addOverlay(polyLine);

        // // 服务区路线
        // let serviceList = res.service_list;
        // let servicePointist = [];
        // serviceList.forEach((item) => {
        //     let point = new window.BMap.Point(item.lon, item.lat);
        //     servicePointist.push(point);
        // })
        // // 画线
        // var polyServiceLine = new window.BMap.Polyline(servicePointist, {
        //     strokeColor: "#ef4136",
        //     strokeWeight: 3,
        //     strokeOpacity: 1
        // });
        // this.map.addOverlay(polyServiceLine);

        // // 添加地图中的自行车
        // let bikeList = res.bike_list;
        // let bikeIcon = new window.BMap.Icon("../../resource/assets/bike.jpg", new window.BMap.Size(36, 42), {
        //     imageSize: new window.BMap.Size(36, 42),
        //     anchor: new window.BMap.Size(18, 42)
        // });
        // bikeList.forEach((item) => {
        //     let p = item.split(",");
        //     let point = new window.BMap.Point(p[0], p[1]);
        //     var bikeMarker = new window.BMap.Marker(point, { icon: bikeIcon });
        //     this.map.addOverlay(bikeMarker);
        // })

        // 添加地图控件
        // this.addMapControl();
    };

    // 添加地图控件
    addMapControl = () => {
        // let map = this.map;
        // // 左上角，添加比例尺
        // var top_right_control = new window.BMap.ScaleControl({anchor: window.BMAP_ANCHOR_TOP_RIGHT});
        // var top_right_navigation = new window.BMap.NavigationControl({anchor: window.BMAP_ANCHOR_TOP_RIGHT});
        // //添加控件和比例尺
        // map.addControl(top_right_control);
        // map.addControl(top_right_navigation);
        // map.enableScrollWheelZoom(true);
        // legend.addLegend(map);
    };

    render(){
        return (
            <div>
               
                <Card >
                    {/* <div>共{this.state.total_count}辆车</div> */}
                    <div id="maper" style={{ width: '100%', height: '700px' }}>
                        {/* <AsyncMap 
                        mapUrl="https://api.map.baidu.com/getscript?v=2.0&ak=1rW8OyVldBy2ex8d3O2GIXuY3erlhYbw"
                        loadingElement={<div>Loading.....</div>}
                        mapContainer={<div style={{ height: '100%' }} />} >
                        <NavigationControl
                        type="big"
                        anchor="top_right"
                        offset={{ width: 0, height: 30 }} />
                        <ScaleControl />
                        <MapTypeControl  />


                        </AsyncMap> */}
                        {this.state.querypointmap?this.state.querypointmap:null}

                    </div>
                </Card>

                <Modal
                    title={this.state.filterTitle}
                    visible={this.state.isFilterVisible}
                    // onOk={this.handleFilterSubmit}
                    width={600}
                    footer={[
                    <Button key='clean' type="dashed" onClick={()=>{
                        this.props.updateMinasim({state:'',bangown:''})
                        this.filterForm.props.form.resetFields()
                        this.filterForm.props.form.validateFields((err, values) => {
                            if (!err) {
                              console.log('Received filter of form: ', values);
                  
                              this.setState({
                                //   isFilterVisible:false,
                                  ...values
                              })
                              
                            }
                        })
                        // this.setState({

                        // })
                    }}>清除</Button>,
                    <Button key='cancel' onClick={()=>{
                        this.setState({
                        isFilterVisible:false,
                    })}}>取消</Button>,
                    <Button key='ok' type="primary" onClick={this.handleFilterSubmit}>筛选</Button>,
                ]}
                    onCancel={()=>{
                        // this.filterForm.props.form.resetFields();
                        this.setState({
                            isFilterVisible:false,
                        })
                    }}
                >
                    <FilterForm wrappedComponentRef={(inst) => this.filterForm = inst}/>
                </Modal>
            </div>
        );
    }
}

@connect(
    state=>state.user,
    // {logoutSubmit}
  )
class FilterForm extends React.Component{
     onChange(value, dateString) {
        console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', dateString);
      }
      
       onOk(value) {
        console.log('onOk: ', value);
      }

    render(){
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {span: 5},
            wrapperCol: {span: 16}
        };
        const userInfo = this.props.userInfo || {};
        const type = this.props.type;
        //  statevalue = (state)=>{
        //     switch (state){
        //         case "-2": return ("测试");
        //         case "-1": return ("沉默期");
        //         case "0": return ("未激活");
        //         case "1": return ("已激活");
        //         case "2": return ("停机");
        //         case "3": return ("报警");
        //         case "4": return ("失效");
        //         case "5": return ("销户");
        //         default:return "未知状态"
        //     }
        //  }
            // "-1":"沉默期",
            //  "0":  "未激活",
            //  "1":  "已激活",
            // "2":  "停机",
            // "3":  "报警",
            // "4":  "失效",
            // "5":  "销户",
            const rangeConfig = {
                rules: [{ type: 'array', required: true, message: 'Please select time!' }],
              };
        
        return (
            <Form layout="horizontal">
                <FormItem label="设备ICCID" {...formItemLayout}>
                    {
                        
                        getFieldDecorator('ICCID',{
                            rules: [
                                {
                                  required: true,
                                  message: '请输入ICCID号码！',
                                },
                                {max:20,message:'ICCID为19或20位'},
                                {min:19,message:'ICCID为19或20位'}
                              ],
                        })(
                            <Input  placeholder="请输入ICCID"/>
                    )}
                </FormItem>
                <FormItem label="时间段" {...formItemLayout}>
                    {getFieldDecorator('range_picker', rangeConfig)(
                    <RangePicker showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    placeholder={['Start Time', 'End Time']}
                    onChange={this.onChange}
                    onOk={this.onOk}/>)}
                    </FormItem>
                {/* <FormItem label="绑定" {...formItemLayout}>
                    {
                        getFieldDecorator('bangown',{
                            initialValue:this.props.bangown
                            // `${this.props.bangown}`
                        })(
                        <Select >
                            <Option value={"100"}>全部</Option>
                            <Option value={"1"}>已绑定</Option>
                            <Option value={"2"}>未绑定</Option>
                            
                        </Select>
                    )}
                    
                </FormItem> */}
                {/* <FormItem  label= "门户账号" {...formItemLayout}>
                    {
                        
                        getFieldDecorator('userName',{
                            
                        })(
                            <Input  placeholder="请输入门户登录账号"/>
                    )}
                </FormItem>  */}
                {/* <FormItem  label= "是否有效" {...formItemLayout}>
                    {
                        
                        getFieldDecorator('isWork',{
                            
                        })(
                            <Select>
                             <Option value={1}>是</Option>
                             <Option value={2}>否</Option>
                            </Select>
                    )}
                </FormItem>  */}
            
            </Form>
        );
    }
}
FilterForm = Form.create({})(FilterForm);
