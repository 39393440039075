import React from 'react'
import { Route, Redirect, Switch ,BrowserRouter} from 'react-router-dom';
// import 'antd/dist/antd.css';
import { enquireScreen } from 'enquire-js';
import Loadable from 'react-loadable';

// import Home from './containers/Home'
import NotFound from './containers/404'
import Loading from './components/loading'
// import Register from './containers/Register'
import StepReg from './containers/StepReg'
// import RegStp1 from './containers/StepReg/Step1'
// import RegStp2 from './containers/StepReg/Step2'
// import RegStp3 from './containers/StepReg/Step3'
// import Login from './components/login'
// import Dashboard from './containers/Dashboard'
import Admin from './admin'
// import AuthRoute from './components/authroute/authroute'
// import Map from './containers/Map'
// import Source from './containers/Source'
// import Sims from './containers/Sims'
// import Uploads from './containers/Uploads'
// import InOrOut from './containers/InOrOut'
// import Bmmanage from './containers/Bmmanage'

const MyLoadingComponent = ({ isLoading, error }) => {
  if (isLoading) {
    return <Loading/>;
  }
  else if (error) {
    console.log(error)
    return <NotFound/>;
  }
  else {
    return null;
  }
}

const Dashboard = Loadable({
  loader: () => import('./containers/Dashboard'),
  loading: MyLoadingComponent
})

const Bmmanage = Loadable({
  loader: () => import('./containers/Bmmanage'),
  loading: MyLoadingComponent
})

const Dbproject = Loadable({
  loader: () => import('./containers/Dbproject'),
  loading: MyLoadingComponent
})

const Setting = Loadable({
  loader: () => import('./containers/Setting'),
  loading: MyLoadingComponent
})
const AlertOverlay = Loadable({
  loader: () => import('./containers/Overlay'),
  loading: MyLoadingComponent
})

const AlertList = Loadable({
  loader: () => import('./containers/Alertlist'),
  loading: MyLoadingComponent
})

const Map = Loadable({
  loader: () => import('./containers/Map'),
  loading: MyLoadingComponent
})

const ElectricFence = Loadable({
  loader: () => import('./containers/Electricfence'),
  loading: MyLoadingComponent
})

const Source = Loadable({
  loader: () => import('./containers/Source'),
  loading: MyLoadingComponent
})

const Download = Loadable({
  loader: () => import('./containers/Download'),
  loading: MyLoadingComponent
})

const Sims = Loadable({
  loader: () => import('./containers/Sims'),
  loading: MyLoadingComponent
})

const Minasims = Loadable({
  loader: () => import('./containers/Minasims'),
  loading: MyLoadingComponent
})

const Cartype = Loadable({
  loader: () => import('./containers/Cartypemanage'),
  loading: MyLoadingComponent
})

const Car = Loadable({
  loader: () => import('./containers/Car'),
  loading: MyLoadingComponent
})

const Minausers = Loadable({
  loader: () => import('./containers/Minausers'),
  loading: MyLoadingComponent
})
const MinaPrice = Loadable({
  loader: () => import('./containers/MinaPrice'),
  loading: MyLoadingComponent
})

const MinaPayLog = Loadable({
  loader: () => import('./containers/MinaPayLog'),
  loading: MyLoadingComponent
})

const Uploads = Loadable({
  loader: () => import('./containers/Uploads'),
  loading: MyLoadingComponent
})

const InOrOut = Loadable({
  loader: () => import('./containers/InOrOut'),
  loading: MyLoadingComponent
})

// const RegStp1 = Loadable({
//   loader: () => import('./containers/StepReg/Step1'),
//   loading: MyLoadingComponent
// })

// const RegStp2 = Loadable({
//   loader: () => import('./containers/StepReg/Step2'),
//   loading: MyLoadingComponent
// })
// const StepReg = Loadable({
//   loader: () => import('./containers/StepReg'),
//   loading: MyLoadingComponent
// })

const Login = Loadable({
  loader: () => import('./components/login'),
  loading: MyLoadingComponent
})

const Party = Loadable({
  loader: () => import('./containers/Party'),
  loading: MyLoadingComponent
})

const PartyLog = Loadable({
  loader: () => import('./containers/PartyLog'),
  loading: MyLoadingComponent
})

// const PartyOrder = Loadable({
//   loader: () => import('./containers/PartyOrder'),
//   loading: MyLoadingComponent
// })

// const Admin = Loadable({
//   loader: () => import('./admin'),
//   loading: MyLoadingComponent
// })

const AuthRoute = Loadable({
  loader: () => import('./components/authroute/authroute'),
  loading: MyLoadingComponent
})

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class RouterMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    }
  }
  componentDidMount() {
  // 适配手机屏幕;
  enquireScreen((b) => {
    this.setState({ isMobile: !!b });
  });
  // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；

}
  updatehandler(){
    console.log('bianhua');
  }
    render() {
        return(
          <BrowserRouter>
            <div className = 'root'>
            <AuthRoute></AuthRoute>

              <Switch>
                <Route exact path="/login" component={Login} />
                <Route path="/register" render={()=>
                  <StepReg>
                    <Switch>
                      <Route path="/register/step1" component={RegStp1} />
                      <Route path="/register/step2" component={RegStp2} />
                      <Redirect to="/register/step1" />
                    </Switch>
                  </StepReg>
                }/>
                <Route  path='/admin'  render={()=>
                            <Admin>
                                <Switch>
                                    <Route path='/admin/home' component={Dashboard} />
                                    <Route path='/admin/device/list' component={Minasims} />
                                    <Route path='/admin/device/cartype' component={Cartype} />
                                    <Route path='/admin/device/carlist' component={Car} />
                                    {/* <Route path='/admin/device/uploads' component={Uploads}/>
                                    <Route path='/admin/device/inorout' component={InOrOut}/> */}
                                    <Route path='/admin/setting/download' component={Download}/>
                                    {/* <Route path='/admin/bmmanage/list' component={Bmmanage} /> */}
                                    <Route path='/admin/hmmanage/list' component={Minausers} />
                                    <Route path='/admin/setting/account' component={Setting} />
                                    <Route path='/admin/map/search' component={Map}/>
                                    <Route path="/admin/alert/mapsetting" component={ElectricFence} />
                                    <Route path="/admin/alert/maplist" component={AlertOverlay} />
                                    <Route path="/admin/alert/list" component={AlertList} />
                                    {/* <Route path='/admin/dbmanage/list' component={Dbproject} /> */}
                                    
                                    {/*<Route path="/ui/buttons" component={Buttons} />
                                    <Route path="/admin/alert/mapsetting" component={ElectricFence} />
                                    <Route path="/ui/loadings" component={Loadings} />
                                    <Route path="/ui/notification" component={Notice} />
                                    <Route path="/ui/messages" component={Messages} />
                                    <Route path="/ui/tabs" component={Tabs} />
                                    <Route path="/ui/gallery" component={Gallery} />
                                    <Route path="/ui/carousel" component={Carousel} />
                                    <Route path="/form/login" component={FormLogin} />
                                    <Route path="/form/reg" component={FormRegister} />
                                    <Route path="/table/basic" component={BasicTable} />
                                    <Route path="/table/high" component={HighTable} />
                                    <Route path='/rich' component={Rich} />
                                    <Route path="/city" component={City} />
                                    <Route path="/order" component={Order} />
                                    <Route path='/bikeMap' component={BikeMap} />
                                    <Route path='/user' component={User} />
                                    <Route path="/charts/bar" component={Bar} />
                                    <Route path="/charts/pie" component={Pie} />
                                    <Route path="/charts/line" component={Line} />
                                    <Route path="/permission" component={Permission} />
                                    <Redirect to="/home" />
                                     <Route component={NoMatch} /> */}
                                </Switch>
                            </Admin>
                        } />
                <Route path='*' component={NotFound}/>
              </Switch>
            </div>
          </BrowserRouter>
        )
    }
}

export default RouterMap
