
import React from 'react';
import { Input, Button, Checkbox ,message,Row, Col,Select,Form,Icon} from 'antd';
// import { Form,Icon } from '@ant-design/compatible';
import {Redirect,Link} from 'react-router-dom'
import { connect } from 'react-redux';
import {iccidData,routeData} from '../../redux/user.redux'
import request from '../../util/request'
import scanlogo from '../../../static/images/scanlogo.png'
import './Register.less'

const FormItem = Form.Item;
const InputGroup = Input.Group;
@Form.create()
@connect(
	state=>state.user,
	{iccidData,routeData}
)
class RegStep2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      count: 0,
      visible: false,
      loading:false,
      prefix: '86',
    };
  }

  componentDidMount() {
    // this.props.initregTo()
    const { location } = this.props;
    console.log("step2--this.props.iccidNum-->>>"+this.props.iccidNum);
    this.props.routeData(location)
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  _toggleLoading = (status = false) => {
    this.setState({
      loading: status
    })
  }
  changePrefix = value => {
    this.setState({
      prefix: value,
    });
  };
  onGetCaptcha = e => {
    // e.preventDefault()
    // this.props.form.validateFields(async (err, values) => {
        // if(!err){
          let count = 59;
          this.setState({ count });
          this.interval = setInterval(() => {
            count -= 1;
            this.setState({ count });
            if (count === 0) {
              clearInterval(this.interval);
            }
          }, 1000);
      //   }
      // })
  };


  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields({ force: true },async (err, values) => {
      if(!err){
          // this.props.register(values,this._toggleLoading)
          request(this._toggleLoading)({
            method: 'post',
            url: '/admin/bindphone',
            data: {
              ...values
            }
          })
          .then(res => {
            console.log("res---->>>>>",res)
                this.props.history.replace('/register/step3')
          })
        }
    })
  };

  render() {
    let {getFieldDecorator} = this.props.form;
    console.log("step2props->>>"+JSON.stringify(this.props) );
    return (this.props.iccidNum?(
      <div className="register-stp2">
        <div className="reg-form2" style={{height: '440px'}}>
        <Form onSubmit={this.handleSubmit} >
          <FormItem>
            {getFieldDecorator('iccidNum', {
              initialValue: this.props.iccidNum,
              rules: [
                {
                  required: true,
                  message: '请输入ICCID',
                }
              ],
            })(<Input size="large"  />)}
          </FormItem>
          <FormItem>
            <InputGroup compact>
              <Select
                size="large"
                value={this.state.prefix}
                onChange={this.changePrefix}
                style={{ width: '25%' }}
              >
                <Option value="86">+86</Option>
              </Select>
              {getFieldDecorator('mobile', {
                rules: [
                  {
                    required: true,
                    message: '请输入手机号！',
                  },
                  {
                    pattern: /^1\d{10}$/,
                    message: '手机号格式错误！',
                  },
                ],
              })(<Input size="large" style={{ width: '75%' }} placeholder="11位手机号" />)}
            </InputGroup>
          </FormItem>
          <FormItem>
            <Row gutter={8}>
              <Col span={16}>
                {getFieldDecorator('captcha', {
                  rules: [
                    {
                      required: true,
                      message: '请输入验证码！',
                    },
                  ],
                })(<Input size="large" placeholder="验证码" />)}
              </Col>
              <Col span={8}>
                <Button
                  size="large"
                  disabled={this.state.count}
                  className="getCaptcha"
                  onClick={this.onGetCaptcha}
                >
                  {this.state.count ? `${this.state.count} s` : '获取验证码'}
                </Button>
              </Col>
            </Row>
          </FormItem>
          <FormItem>
            <Button
              size="large"
              loading={this.state.loading}
              className="submit2"
              type="primary"
              htmlType="submit"
            >
              下一步
            </Button>
          </FormItem>
        </Form>

      </div>
    </div>
  ):<Redirect to="/register"/>

    );
  }
}

export default RegStep2;
