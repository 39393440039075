/**
 * 
 */
import React, { Component } from 'react';
import { Menu, Icon, Layout, Badge, Popover,Modal,Form,Input,notification } from 'antd';
import axios from 'axios'
import screenfull from 'screenfull';
// import { gitOauthToken, gitOauthInfo } from '../axios';
import { queryString } from '../../util';
import browserCookie from 'browser-cookies'
import avater from '../../../static/images/b1.jpg';
import SiderCustom from '../SiderCustom';
import { connect } from 'react-redux';
import {logoutSubmit,updateMinasim} from '../../redux/user.redux'
import { withRouter } from 'react-router-dom';
import io from 'socket.io-client'
const { Header } = Layout;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const FormItem = Form.Item;

const socket = io(process.env.NODE_ENV=="development"?'ws://localhost:10086/wss':'wss://alert.mnoiot.com/wss')



@withRouter
@connect(
  state=>state.user,
  {logoutSubmit,updateMinasim}
)
class HeaderCustom extends Component {
    state = {
        user: '',
        visible: false,
        isShowModal:false,
        title:'修改密码',
        alerticcid:null,
        alertcount:0
    };
      

     
    componentDidMount() {
        const QueryString = queryString();
        console.log(process.env.NODE_ENV)
        // 连接服务器, 得到与服务器的连接对象
        

        // 绑定监听, 接收服务器发送的消息
        socket.connect()
        socket.on('connect', () => {
            // console.log('已连接')
            // this.setState({
            //   isConnect:true,
            //   isDisable:true,
            //   socket})
           console.log("连接成功！！！")
            socket.emit("chat message","已连接")

            socket.on('GsmClientTestalert',  (data)=> {
                console.log('客户端接收服务器发送的消息', data)
                // console.log('alert props', this.props)
                this.props.updateMinasim({
                    alertmsgcount:(this.props.alertmsgcount+1)
                })
                // this.setState({alertcount:tmpalertcount})
                this.openNotificationWithIcon("error",data)
            })

            // socket.emit('chat message', {name: 'abc'})
            // console.log('客户端向服务器发消息', {name: 'abc'})

            
          })
  
        
       
        // const _user = JSON.parse(localStorage.getItem('user')) || '测试';
        // if (!_user && QueryString.hasOwnProperty('code')) {
        //     gitOauthToken(QueryString.code).then(res => {
        //         gitOauthInfo(res.access_token).then(info => {
        //             this.setState({
        //                 user: info
        //             });
        //             localStorage.setItem('user', JSON.stringify(info));
        //         });
        //     });
        // } else {
        //     this.setState({
        //         user: _user
        //     });
        // }

    
   
    };
    screenFull = () => {
        if (screenfull.enabled) {
            screenfull.request();
        }

    };
     openNotificationWithIcon = (type,alerticcid) => {
        notification[type]({
          message: '预警信息',
          duration:4,//4秒预警显示
          top:35,
          key:"alertinfo",
          description:
            `预警设备号：${alerticcid},请注意查看`,
        });
      };
    menuClick = e => {
        console.log(e);
        e.key === 'logout' && this.logout();
    };
    logout = () => {
        // localStorage.removeItem('user');
        browserCookie.erase('electric:sess')
        this.props.logoutSubmit()
        this.props.history.push('/login')
       
        // const alert = Modal.alert
        //
		    //   alert('注销', '确认退出登录吗???', [
		    //       { text: '取消', onPress: () => console.log('cancel') },
		    //       { text: '确认', onPress: () => {
		    //   	         // browserCookie.erase('userid')
		    //   	         this.props.logoutSubmit()
		    //        }}
		    //   ])
        // this.props.history.push('/login')
    };
    popoverHide = () => {
        this.setState({
            visible: false,
        });
    };
    handleVisibleChange = (visible) => {
        this.setState({ visible });
    };
    changeUserinfo=()=>{
        this.setState({
            isShowModal:true
        })
    }
    handleChangePass=()=>{
        let data = this.adminUserInfoForm.props.form.getFieldsValue();
        console.log('changepassword--->>',data)
        axios.post('/admin/changeadminpassword',{
            ...data,
            phonenumber:this.props.phonenumber,          
    },{
        type: 'json'
    })
        .then(res=>{
            
            if(res){
                this.adminUserInfoForm.props.form.resetFields();
                this.setState({
                    isShowModal:false
                })
            }
            // console.log('form---->>>>',res.data)

            
        })
        return
    }
    badgeclicked=()=>{
        console.log("badgeclicked-->>")
        if(this.props.alertmsgcount>0){
            this.props.history.push('/admin/alert/list')
        }
        this.props.updateMinasim({
            alertmsgcount:0
        })
        // this.setState({
        //     alertcount:0
        // })
        
    }
    render() {
        const { responsive, path } = this.props;
        let avater ='https://qiniu.mnoiot.com/bms/touxiang/f0b658e764841414b4eb2b508302a448.jpeg';
        return (
            <Header style={{ background: '#fff', padding: 0, height: 65}} className="custom-theme" >
                {

                        <Icon
                            className="trigger custom-trigger"
                            type={this.props.collapsed ? 'menu-unfold' : 'menu-fold'}
                            onClick={this.props.toggle}
                        />

                }
                <Menu
                    mode="horizontal"
                    style={{ lineHeight: '64px', float: 'right' }}
                    onClick={this.menuClick}
                >
                    <Menu.Item key="full" onClick={this.screenFull} >
                        <Icon type="arrows-alt" onClick={this.screenFull} />
                    </Menu.Item>
                    
                    <Menu.Item key="1" onClick={this.badgeclicked}>
                      
                            <Badge count={this.props.alertmsgcount} overflowCount={10} style={{marginLeft: 10}}>
                                <Icon type="notification" />
                        </Badge>
                       
                        
                    </Menu.Item>
                   
                    
                    <SubMenu title={<span className="avatar"><img src={avater} alt="头像" /><i className="on bottom b-white" /></span>}>
                        <MenuItemGroup title="用户中心">
                            <Menu.Item key="setting:1">你好 - {this.props.username}</Menu.Item>
                            
                            <Menu.Item key="logout"><span onClick={this.logout}>退出登录</span></Menu.Item>
                        </MenuItemGroup>
                        <MenuItemGroup title="设置中心">
                            <Menu.Item key="setting:3"><span onClick={this.changeUserinfo}>密码设置</span></Menu.Item>
                        </MenuItemGroup>
                    </SubMenu>
                </Menu>
                <Modal
                    title={this.state.title}
                    visible={this.state.isShowModal}
                    onOk={this.handleChangePass}
                    width={550}
                    onCancel={()=>{
                        this.adminUserInfoForm.props.form.resetFields();
                        this.setState({
                            isShowModal:false
                        })
                    }}
                >
                    <AdminUserInfoForm  wrappedComponentRef={(inst) => this.adminUserInfoForm = inst}/>
                </Modal>

                <style>{`
                    .ant-menu-submenu-horizontal > .ant-menu {
                        width: 120px;
                        left: -40px;
                    }
                `}</style>
            </Header>
        )
    }
}

class AdminUserInfoForm extends React.Component{

    render(){
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {span: 5},
            wrapperCol: {span: 16}
        };

        // const adminUserInfo = this.props.adminUserInfo || {};
        // const type = this.props.type;
        return (
            <Form layout="horizontal">

                <FormItem label="原始密码" {...formItemLayout}>
                    {
                        
                        getFieldDecorator('oldpassword',{
                            initialValue:'',
                            rules: [
                                {
                                  required: true,
                                  message: '请输入原始密码！',
                                },
                                
                              ],
                        })(
                            <Input type="password" placeholder="请输入原始密码"/>
                        )
                    }
                </FormItem>

                <FormItem label="新密码" {...formItemLayout}>
                    {
                        
                        getFieldDecorator('newpassword',{
                            initialValue:'',
                            rules: [
                                {
                                  required: true,
                                  message: '请输入新密码！',
                                },
                                
                              ],
                        })(
                            <Input  placeholder="请输入新密码" type="password"/>
                        )
                    }
                </FormItem>

                <FormItem label="确认新密码" {...formItemLayout}>
                    {
                        
                        getFieldDecorator('againnewpassword',{
                            initialValue:'',
                            rules: [
                                {
                                  required: true,
                                  message: '请再次输入新密码！',
                                },
                               
                              ],
                        })(
                            <Input  placeholder="请再次输入新密码" type="password"/>
                        )
                    }
                </FormItem>
               
            </Form>
        );
    }
}
AdminUserInfoForm = Form.create({})(AdminUserInfoForm);


export default HeaderCustom;
