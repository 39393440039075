
import axios from 'axios'
import request from '../util/request'
import {getRedirectPath} from '../util'
import Utils from '../util/utils'
import { Button,} from 'antd'
import { map } from 'lodash'


const AUTH_SUCCESS = 'AUTH_SUCCESS'
const ICCID_SUCCESS = 'ICCID_SUCCESS'
const INITREGTO = 'INITREGTO'
const LOGOUT = 'LOGOUT'
const ERROR_MSG = 'ERROR_MSG'
const LOAD_DATA = 'LOAD_DATA'
const REDIRECTTO = 'REDIRECTTO'
const ICCIDDATA = 'ICCIDDATA'
const initState={
	redirectTo:'',
	msg:'',
	username:'',
	type:'',
	phonenumber:'',
	role:'',
	iccidNum:'',
	bangown:'',//微信小程序设备，筛选条件，是否已绑定
	state:'',//微信小程序设备，筛选条件，卡状态
	isshow:'',//微信小程序设备，筛选条件，价格是否展示
	trade_state:'',//微信小程序设备，筛选条件，交易状态
	search:'',//微信小程序设备,搜索条件
	minausersearch:'',//微信小程序用户,搜索条件
	minapaylogsearch:'',//微信小程序充值记录,搜索条件
	downloadsearch:'',
	mapsearch:'',
	cartypesearch:'',
	carsearch:'',
	minasimsortField:'',//微信小程序排序字段
	minasimsortOrder:'',//微信小程序排序方式
	minapricesortField:'',//微信小程序排序字段
	minapricesortOrder:'',//微信小程序排序方式
	// minapaylogsortField:'',//微信小程序排序字段
	// minapaylogsortOrder:'',//微信小程序排序方式
	minausersortField:'',//微信小程序排序字段
	minausersortOrder:'',//微信小程序排序方式
	minapaylogsortField:'',//微信小程序排序字段
	minapaylogsortOrder:'',//微信小程序排序方式
	downloadsortField:'',
	downloadsortOrder:'',
	bmlistsortField:'',
	dbmanagesortOrder:'',
	adminuserlistsortField:'',
	adminusersortOrder:'',
	dbprojectlistsortField:'',
	dbprojectsortOrder:'',
	overlaysearch:'',
	overlaysortField:'',
	overlaysortOrder:'',
	alertsearch:'',
	alertsortField:'',
	alertsortOrder:'',
	cartypelistsortField:'',
	cartypemanagesortOrder:'',
	carlistsortField:'',
	carmanagesortOrder:'',
	alertmsgcount:0,
	overlaysearch:'',
	alertpoint:{},
	alertoverlay:{},
	minasimfield:[
		{
			title: '绑定',
			isSelect:true,
			//1是选中，2是未选择
		  //   dataIndex: 'operate',
			key: 'operationminibang',
			align:'center',
		  //   sorter: true,
		  //   render: name => `${name.first} ${name.last}`,
			width: 80,
			fixed:'left',
			render: () => <Button type="link" onClick={()=>this.handleOperator('bang',row)}>绑定</Button>,
		  },
		
		{
			title: 'ICCID',
			isSelect:true,
			key: 'iccidNum',
			ellipsis: true,
			// fixed:'left',
			dataIndex: 'iccidNum',
			// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
			width: 250,
		  },
		{
			title: '车牌号',
			isSelect:true,
			key: 'CARNUM',
			ellipsis: true,
			// fixed:'left',
			dataIndex: 'carid.carnum',
			// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
			width: 150,
		},
		{
			title: '车辆类型',
			isSelect:true,
			key: 'CARTYPE',
			ellipsis: true,
			// fixed:'left',
			dataIndex: 'cartypeid.cartype',
			// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
			width: 150,
		},
		{
			title:'在线',
			isSelect:true,
			key:'devstate',
			ellipsis:true,
			dataIndex:'devstate',
			render(devstate){
				switch (devstate){
					
					case 0: return ("否");
					case 1: return ("是");
					
					default:return "未知"
				}
			  
			},
			width:80,
		  },
		  {
			title: '联系人',
			isSelect:true,
			key: 'contener',
			ellipsis: true,
			// fixed:'left',
			dataIndex: 'minauserid.name',
			// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
			width: 80,
		},
		{
			title: '联系人手机号',
			isSelect:true,
			key: 'contenerph',
			ellipsis: true,
			// fixed:'left',
			dataIndex: 'minauserid.phoneNumber',
			// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
			width: 150,
		},
		
		  {
			title: '设备编码',
			isSelect:true,
			key: 'IMEI',
			ellipsis: true,
			// fixed:'left',
			dataIndex: 'IMEI',
			// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
			width: 200,
		  },
		  {
			title: 'IMSI',
			isSelect:true,
			key: 'imsi',
			ellipsis: true,
	
			dataIndex: 'imsi',
			// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
			width: 200,
		  },
		  {
			title: 'MSISDN',
			isSelect:true,
			key: 'msisdn',
			ellipsis: true,
	
			dataIndex: 'msisdn',
			// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
			width: 200,
		  },
		//   {
		// 	title: '手机号',
		// 	isSelect:true,
		// 	key: 'mobile',
		// 	ellipsis: true,
	
		// 	dataIndex: 'banguserid.phoneNumber',
		// 	// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
		// 	width: 150,
		//   },
		  
		  {
			title: '卡状态',
			isSelect:true,
			key: 'state',
			ellipsis: true,
	
			dataIndex: 'state',
			render(state){
				switch (state){
					case "-2": return ("测试");
					case "-1": return ("沉默期");
					case "0": return ("未激活");
					case "1": return ("已激活");
					case "2": return ("停机");
					case "3": return ("报警");
					case "4": return ("失效");
					case "5": return ("销户");
					default:return "未知状态"
				}
			  
			},
			
			// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
			width: 100,
		  },
		  
		  {
			title: '绑定状态',
			key: 'bangown',
			isSelect:true,
			ellipsis: true,
	
			dataIndex: 'bangown',
			render(bangown){
				switch (bangown){
					case "1": return ("已绑定");
					case "2": return ("未绑定");
					default:return "未知状态"
				}
			  
			},
			// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
			width: 100,
		  },
		//   {
		//     title: '最后位置',
		//     key: 'location',
		//     isSelect:true,
		//     ellipsis: true,
	
		//     dataIndex: 'location',
		//     render: () => <Button type="link">位置</Button>,
		//     // filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
		//     width: 100,
		//   },
		{
			title: '更新时间',
			isSelect:true,
			key: 'updateAt',
			ellipsis: true,
			render:Utils.formateDateWX,
			sorter: true,
			dataIndex: "meta.updatedAt",
			width: 200,
		  },
		  {
			title: '创建时间',
			isSelect:true,
			key: 'createAt',
			ellipsis: true,
	
			dataIndex: "meta.createdAt",
			sorter: true,
			render:Utils.formateDateWX,
			
		  },
		  ],


		  minauserfield : [ {
			title: '操作',
		  //   dataIndex: 'operate',
			key: 'operationmini',
			align:'center',
			isSelect:true,
		  //   sorter: true,
		  //   render: name => `${name.first} ${name.last}`,
			width: 80,
			fixed:'left',
			render: () => <Button type="link">编辑</Button>,
		  },
		  {
			  title: '手机号',
			  key: 'phoneNumber',
			  ellipsis: true,
			  isSelect:true,
			  // fixed:'left',
			  dataIndex: 'phoneNumber',
			  // filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
			  width: 150,
			},
		
			{
			  title: '账户余额',
			  key: 'money',
			  ellipsis: true,
			  isSelect:true,
			  dataIndex: 'money',
			  // filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
			  width: 100,
			},
			{
			  title: 'openid',
			  key: 'openid',
			  ellipsis: true,
			  isSelect:true,
			  dataIndex: 'openid',
			  // filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
			  width: 280,
			},
			
			{
			  title: '默认iccid',
			  key: 'defaultIccid',
			  ellipsis: true,
			  isSelect:true,
			  dataIndex: 'defaultIccid',
			  // filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
			  width: 250,
			},
		
			{
			  title: '创建时间',
			  key: 'usercreateAt',
			  ellipsis: true,
			  isSelect:true,
			  dataIndex: "meta.createdAt",
			  sorter: true,
			  render:Utils.formateDate,
		
			},
			{
			  title: '更新时间',
			  key: 'userupdateAt',
			  ellipsis: true,
			  isSelect:true,
			  render:Utils.formateDate,
			  sorter: true,
			  dataIndex: "meta.updatedAt",
		
			},
		],

		minapaylogfield:[
			{
			  title: '操作',
			//   dataIndex: 'operate',
			  key: 'operationmini',
			  align:'center',
			//   sorter: true,
			//   render: name => `${name.first} ${name.last}`,
			  width: 80,
			  fixed:'left',
			  isSelect:true,
			  render: () => <Button type="link">编辑</Button>,
			},
			{
				title: '名称',
				key: 'payname',
				ellipsis: true,
				// fixed:'left',
				dataIndex: 'name',
				isSelect:true,
				// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				width: 150,
			  },
		
				{
				title: '手机号',
				key: 'phoneNumberpay',
				ellipsis: true,
				isSelect:true,
				dataIndex: 'phoneNumber',
				// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				width: 150,
			  },
			  {
				title: '充值金额',
				key: 'showpricepay',
				ellipsis: true,
				isSelect:true,
				dataIndex: 'showprice',
				// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				width: 150,
			  },
			  {
				title: '实际购买花费',
				key: 'totalFeepay',
				ellipsis: true,
				isSelect:true,
				dataIndex: 'totalFee',
				// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				width: 150,
			  },
			  {
				title: '自有订单号',
				key: 'out_trade_nopay',
				ellipsis: true,
				isSelect:true,
				dataIndex: 'out_trade_no',
				// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				width: 150,
			  },
			  {
				title: '微信订单号',
				key: 'transaction_idpay',
				ellipsis: true,
				isSelect:true,
				dataIndex: 'transaction_id',
				// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				width: 150,
			  },
			  
			  {
				title: '是否成功',
				key: 'failorsucc',
				ellipsis: true,
				isSelect:true,
				dataIndex: 'trade_state',
				// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				width: 100,
				 render(trade_state){
					switch (trade_state){
						case "SUCCESS": return ("成功");
						case "FAIL": return ("失败");
						default:return "未知状态"
					}
				}
			  },
			  {
				title: '创建时间',
				key: 'usercreateAt',
				ellipsis: true,
				sorter: true,
				isSelect:true,
				dataIndex: "meta.createdAt",
				render:Utils.formateDate,
		
			  },
			  {
				title: '更新时间',
				key: 'userupdateAt',
				ellipsis: true,
				sorter: true,
				isSelect:true,
				render:Utils.formateDate,
				dataIndex: "meta.updatedAt",
		
			  },
		  ],
		  partyfield:[
			{
			  title: '操作',
			//   dataIndex: 'operate',
			  key: 'operationmini',
			  align:'center',
			  isSelect:true,
			//   sorter: true,
			//   render: name => `${name.first} ${name.last}`,
			  width: 80,
			  fixed:'left',
			  render: () => <Button type="link">编辑</Button>,
			},
			{
				title: '活动名称',
				key: 'title',
				ellipsis: true,
				isSelect:true,
				// fixed:'left',
				dataIndex: 'title',
				// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				width: 150,
			  },
			  {
				title: '活动简介',
				key: 'note',
				ellipsis: true,
				isSelect:true,
				// fixed:'left',
				dataIndex: 'note',
				// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				width: 150,
			  },
		
			  {
				title: '活动经费',
				key: 'totalfee',
				isSelect:true,
				ellipsis: true,
		
				dataIndex: 'totalfee',
				// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				width: 150,
			  },
			  {
				title: '剩余经费',
				key: 'leftfee',
				isSelect:true,
				ellipsis: true,
		
				dataIndex: 'leftfee',
				// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				width: 150,
			  },
			  {
				title: '上游返利',
				key: 'upfee',
				isSelect:true,
				ellipsis: true,
		
				dataIndex: 'upfee',
				// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				width: 150,
			  },
			  {
				title: '下游返利',
				key: 'downfee',
				isSelect:true,
				ellipsis: true,
		
				dataIndex: 'downfee',
				// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				width: 150,
			  },
			  
			  {
				title: '是否展示',
				key: 'partyisshow',
				isSelect:true,
				ellipsis: true,
		
				dataIndex: 'isshow',
				// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				width: 100,
				render(isshow){
					switch (isshow){
						case "1": return ("是");
						case "2": return ("否");
						default:return "未知状态"
					}
				}
			  },
			  {
				title: '开始时间',
				key: 'starttime',
				ellipsis: true,
				isSelect:true,
				dataIndex: "starttime",
				sorter: true,
				render:Utils.formateDate,
		 
			  },
			  {
				title: '结束时间',
				key: 'endtime',
				ellipsis: true,
				isSelect:true,
				dataIndex: "endtime",
				sorter: true,
				render:Utils.formateDate,
		 
			  },
			  {
				title: '创建时间',
				key: 'usercreateAt',
				ellipsis: true,
				isSelect:true,
				dataIndex: "meta.createdAt",
				sorter: true,
				render:Utils.formateDate,
		 
			  },
			  {
				title: '更新时间',
				key: 'userupdateAt',
				isSelect:true,
				ellipsis: true,
				render:Utils.formateDate,
				sorter: true,
				dataIndex: "meta.updatedAt",
		
			  },
		
		  ],
		  partylogfield:[
			{
				title: '操作',
			  //   dataIndex: 'operate',
				key: 'operationmini',
				align:'center',
				isSelect:true,
			  //   sorter: true,
			  //   render: name => `${name.first} ${name.last}`,
				width: 80,
				fixed:'left',
				render: () => <Button type="link">编辑</Button>,
			  },
			  {
				  title: '活动id',
				  key: 'partyid',
				  ellipsis: true,
				  isSelect:true,
				  // fixed:'left',
				  dataIndex: 'partyid',
				  // filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				  width: 150,
				},
				{
				  title: '分享用户手机',
				  key: 'upuser',
				  ellipsis: true,
				  isSelect:true,
				  // fixed:'left',
				  dataIndex: 'upuser',
				  // filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				  width: 150,
				},
				{
					title: '收件人姓名',
					key: 'name',
					isSelect:true,
					ellipsis: true,
			
					dataIndex: 'name',
					// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
					width: 100,
				  },
		  
				{
				  title: '收件人手机号',
				  key: 'sjphone',
				  isSelect:true,
				  ellipsis: true,
		  
				  dataIndex: 'phone',
				  // filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				  width: 150,
				},
				{
				  title: '身份证号',
				  key: 'idcard',
				  isSelect:true,
				  ellipsis: true,
		  
				  dataIndex: 'idcard',
				  // filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				  width: 150,
				},
				{
				  title: '收件区域',
				  key: 'address',
				  isSelect:true,
				  ellipsis: true,
		  
				  dataIndex: 'address',
				  // filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				  width: 150,
				},
				{
				  title: '详细地址',
				  key: 'liteaddress',
				  isSelect:true,
				  ellipsis: true,
		  
				  dataIndex: 'liteaddress',
				  // filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				  width: 150,
				},
				
				{
				  title: '快递单号',
				  key: 'kdid',
				  isSelect:true,
				  ellipsis: true,
		  
				  dataIndex: 'kdid',
				  // filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				  width: 100,
				  
				},
				{
				  title: '快递种类',
				  key: 'kdtype',
				  ellipsis: true,
				  isSelect:true,
				  dataIndex: "kdtype",
				  sorter: true,
		   
				},
				{
				  title: '支付状态',
				  key: 'alreadypay',
				  ellipsis: true,
				  isSelect:true,
				  dataIndex: "alreadypay",
				  sorter: true,
				  render(alreadypay){
					  switch (alreadypay){
						  case "1": return ("未支付");
						  case "2": return ("已支付");
						  case "3": return ("支付失败");
						  case "4": return ("支付中");
						  default:return "未知状态"
					  }
				  }
		   
				},
				{
				  title: '创建时间',
				  key: 'partylogcreateAt',
				  ellipsis: true,
				  isSelect:true,
				  dataIndex: "meta.createdAt",
				  sorter: true,
				  render:Utils.formateDate,
		   
				},
				{
				  title: '更新时间',
				  key: 'partylogupdateAt',
				  isSelect:true,
				  ellipsis: true,
				  render:Utils.formateDate,
				  sorter: true,
				  dataIndex: "meta.updatedAt",
		  
				}
		  ],

	minasimfieldSelect:[],//微信小程序动态显示字段,已选择
	minapricefieldSelect:[],//微信小程序动态显示字段,已选择
	minauserfieldSelect:[],//微信小程序动态显示字段,已选择
	minapaylogfieldSelect:[],//微信小程序动态显示字段,已选择
	partyfieldSelect:[],//活动列表,已选择
	partylogfieldSelect:[],//活动列表,已选择
}

const initReg={
	iccidNum:'',
}
// reducer
export function user(state=initState, action){
	switch(action.type){
		case AUTH_SUCCESS:
			return {...state,redirectTo:'/admin/home',...action.payload}
		case ICCID_SUCCESS:
			return {...state,regTo:'/regphone',...action.payload}
		case LOAD_DATA:
			return {...state,...action.payload}
		case ERROR_MSG:
			return {...state, isAuth:false, msg:action.msg}
		case LOGOUT:
			return {...initState,redirectTo:'/login'}
		case REDIRECTTO:
			return{...state,...action.payload}
		case ICCIDDATA:
			return {...state,...action.payload}
		default:
			return state
	}
}

function authSuccess(obj){
	const {...data} = obj
	console.log("data---->>>>>",data)
	return {type: AUTH_SUCCESS, payload:data}
}

function loadSuccess(obj){
	const {...data} = obj
	return {type: LOAD_DATA, payload:data}
}

function iccidSuccess(obj){
	const {...data} = obj
	console.log("iccid---->>>>>",data)
	return {type: ICCID_SUCCESS, payload:data}
}

function errorMsg(msg){
	return { msg, type:ERROR_MSG }
}

export function loadData(userinfo){
	return { type:LOAD_DATA, payload:userinfo}
}

// export function loadData(){
// 	return dispatch=>{
// 		request(()=>{})({
// 			method: 'get',
// 			url: '/admin/info'
// 		})
// 		.then(res => {
// 			console.log("loadRes---->>>>>",res)
// 					dispatch(loadSuccess(res))
// 		})
// 	}
// }
function logout(){
	return { type:LOGOUT }
}
export function logoutSubmit(){
	return dispatch=>{
		axios.get('/admin/logout')
		.then(res => {
			// console.log("logoutres---->>>>>",res)
					dispatch(logout())
		})
		.catch(err=>{
			console.error("res---->>>>>",err)
			throw err
		})
	}

}

export function login(values,fn){
	return dispatch=>{
		request(fn)({
			method: 'post',
			url: '/admin/login',
			data: {
				...values
			}
		})
		.then(res => {
			console.log("res---->>>>>",res)
			if(res?.phonenumber){
				dispatch(authSuccess(res))
			}else{
				console.log("<<<----loginerr---->>>>>")
			}
					
		})
		.catch(err=>{
			console.error("res---->>>>>",err)
			throw err
		})
	}
}

export function register(values,fn){
	return dispatch=>{
		request(fn)({
			method: 'post',
			url: '/admin/register',
			data: {
				...values
			}
		})
		.then(res => {
			console.log("res---->>>>>",res)
					dispatch(iccidSuccess(res))
		})
	}
}

export function routeData(routeDir){
	const {...data} = routeDir
	// console.log("data---->>>>>",data)
	return { type:REDIRECTTO ,payload:routeDir}
}

export function iccidData(iccid){
	const {...data} = iccid
	// console.log("data---->>>>>",data)
	return { type:ICCIDDATA ,payload:data}
}

export function updateMinasim(minasimdata){
	// const {...data} = routeDir
	// console.log("update--minasimdata-->>>>>",minasimdata)
	return { type:LOAD_DATA ,payload:minasimdata}
}

export function updateParty(partydata){
	// console.log("update--partydata-->>>>>",partydata)
	return { type:LOAD_DATA ,payload:partydata}
}


export function updatePartyLog(partylogdata){
	// console.log("update--partylogdata-->>>>>",partylogdata)
	return { type:LOAD_DATA ,payload:partylogdata}
}

export function updateBmlist(bmlistdata){
	// console.log("update--partylogdata-->>>>>",bmlistdata)
	return { type:LOAD_DATA ,payload:bmlistdata}
}

export function updateSetting(settingdata){
	// console.log("update--settingdata-->>>>>",settingdata)
	return { type:LOAD_DATA ,payload:settingdata}
}

export function updateDbproject(Dbprojectdata){
	console.log("update--settingdata-->>>>>",Dbprojectdata)
	return { type:LOAD_DATA ,payload:Dbprojectdata}
}